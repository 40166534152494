@import "styles/variables";

$border-style: 1px solid #eeeeee;

.megamenu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  background: $primary-color;
  height: 50px;
  flex-wrap: nowrap;
  position: relative;

  @media (min-width: $md) {
    display: flex;
  }

  > li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    &:not(:last-child) {
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }

    &.special {
      background: #eec547;
    }

    > a {
      color: #ffffff;
      padding: 13px 10px;
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);

      &:hover {
        background: rgba(0, 0, 0, 0.06);
      }
    }

    &:hover {
      .submenu {
        display: block !important;
      }
    }

    .submenu {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      background: #ffffff;
      padding: 20px 10px 10px 10px;
      display: none;
      box-shadow: 0px 5px 20px -5px rgb(0, 0, 0, 0.1);
      max-height: 80vh;
      overflow: auto;

      .submenuColsWrapper {
        border: $border-style;
        flex-wrap: wrap;
        display: flex;
        width: 100%;
      }

      .submenuCol {
        padding: 20px;
        position: relative;
        border-left: $border-style;
        border-top: $border-style;
        overflow: hidden;
        min-height: 195px;
        padding-right: 125px;

        &.virtual ~ .virtual {
          border-left: none !important;
        }
        
        &:nth-child(-n + 4) {
          border-top: none;
        }

        &:nth-child(4n + 1) {
          border-left: none;
        }

        .categoryImage {
          position: absolute;
          top: 0;
          right: 0;
          img{
            max-width: 120px;
          }
        }
      }

      h3 {
        a {
          color: $primary-color;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          a {
            color: #000000;
            line-height: 23px;
            display: inline-block;
            font-weight: 300;
            font-size: 13px;

            &.showMore {
              margin-top: 5px;
              display: flex;
              align-items: center;
              &::after {
                content: "›";
                margin-left: 10px;
                font-size: 16px;
              }
            }

            &.primary {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
