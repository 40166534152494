@import "styles/variables";

#footer {
  background-color: #202020;
  padding: 50px 0;
  color: white;
  font-family: Merriweather;
  line-height: 28px;
  &.xmasFooter{
    background: $primary-color;
    position: relative;
    h3{
      color: white;
    }
    a{
      &:hover{
        color: black;
      }
    }
  }
  .xmasBg{

  }
  .container {
    max-width: 1220px;
    margin: 0 auto;
  }
  .row {
    position: relative;
    clear: both;
    width: 100%;
    overflow: auto;
    .col {
      width: 50%;
      float: left;
      padding: 0 $general-gutter;
      box-sizing: border-box;
    }
  }
  h3 {
    font-family: "Nunito Sans";
    margin-top: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    margin-bottom: $general-gutter;
    color: $primary-color;
  }
  a {
    color: white;
    text-decoration: none;
    &:hover {
      color: #56ab2f;
    }
  }
  address {
    margin-top: $general-gutter;
  }
  .social-icons {
    margin-top: $general-gutter;
    a {
      margin-right: $general-gutter;
      svg {
        fill: white;
      }
    }
  }
  img {
    &.footer-logo {
      width: 130px;
      max-width: 100%;
    }
  }
  ul {
    padding: 0;
    list-style-type: none;
  }
  @media (max-width: $sm) {
    font-size: 0.85em;
    .container {
      padding: $general-gutter;
    }
    .row {
      .col {
        padding: $general-gutter;
      }
    }
  }
}

//sampion
#footer {
  position: relative;
  margin-top: 40px;
  &.xmasFooter{
    max-width: 100%;
    overflow-x: hidden;
    &::before{
      background-image: url("/xmas/dried-oranges-cinnamon-anise-nuts.png");
      transform: scaleX(-1) rotate(180deg);
      right: -20px;
      margin-top: 0;
      @media (min-width: $xl) {
        width: 300px;
        height: 300px;
      }
    }
  }
  &::before {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    margin-top: -45px;
    background-image: url("https://instance12.admin.grandus.sk/files/attachment/2020-02/4051/a06c8f/footer-sampion.png");
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    width: 140px;
    height: 140px;
    content: "";
  }
  // @media (min-width: $sm) {
  //   &::before {
  //     margin-top: -65px;
  //     width: 130px;
  //     height: 130px;
  //   }
  // }
  // @media (min-width: $md) {
  //   &::before {
  //     width: 170px;
  //     height: 170px;
      
  //   }
  // }
  @media (min-width: $lg) {
    &::before {
      width: 200px;
      height: 200px;
      display: inline-block;
    }
  }
  @media (min-width: $xl) {
    &::before {
      width: 230px;
      height: 230px;
    }
  }
}

footer .row .col:nth-child(3) ul,
footer .row .col:nth-child(4) ul {
  padding-top: $general-gutter;
  margin-top: $general-gutter;
}
footer a.email,
footer a.phone {
  display: block;
}

@media (min-width: 776px) {
  #footer {
    .row .col {
      width: 25%;
    }
    .row {
      .col:nth-child(3) ul,
      .col:nth-child(4) ul {
        border-top: none;
        padding-top: 0;
        margin-top: 0;
      }
    }
  }
}

//COPYRIGHT

#copyright {
  background-color: #111111;
  padding: $general-gutter * 2 0;
  color: white;
  font-family: Merriweather;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  a {
    color: #56ab2f;
    text-decoration: none;
  }
  .container {
    max-width: 1220px;
    margin: 0 auto;
  }
  .row {
    position: relative;
    clear: both;
    width: 100%;
    overflow: auto;
    .col {
      width: 100%;
      padding: 0 $general-gutter;
      box-sizing: border-box;
    }
  }
  @media (max-width: $sm) {
    font-size: 0.8em;
  }
}
