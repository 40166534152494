.userButton{
    cursor: pointer;
    .icon{
        svg{
            width: 24px;
            height: 24px;
        }
    }
    .text{
        display: none;
        margin-left: 5px;
    }
    @media (min-width: 700px) {
        .icon{
            svg{
                width: 14px;
                height: 14px;
            }
        }
        .text{
            display: inline;
        }
    }
}