@import "styles/variables";

$search-input-button: 60px;

.sticky {
  position: sticky;
  top: 0;
  z-index: 20;
}

.infoBox {
  font-family: Merriweather, sans-serif;
  background-color: $primary-color;
  color: white;
  //height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 20px 0;
  position: relative;
  line-height: 20px;
  > * {
    margin: 0;
    padding: 0;
  }
  @media (max-width: $md) {
    padding: 11px $general-gutter;
    font-size: 11px;
  }

  .container {
    width: 100%;
    position: relative;

    @media (max-width: $md) {
      padding-right: 25px;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  &__close {
    position: absolute;
    right: 0;
    color: rgba(0, 0, 0, 0.3);
    transform: rotate(45deg);
    font-size: 20px;
    color: #ffffff;
    opacity: 0.7;
    transition: all ease-in-out 0.1s;

    &:hover {
      color: #ffffff;
      opacity: 1;
    }
  }
}

.wishlistBadge {
  sup {
    right: -3px;
    top: -3px;
  }
}

#finder {
  max-width: 100%;
  background-color: $secondary-color;
  padding: 15px 20px 25px;
  * {
    box-sizing: border-box;
    font-family: "Quicksand", sans-serif;
  }
  h2 {
    color: $primary-color;
    font-weight: bold;
    font-size: 1em;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
  }
  .custom-select {
    width: 100%;
    height: 32px;
    margin-bottom: 15px;
    display: block;
    background-clip: padding-box;
    border: none;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .link {
      font-size: 0.8em;
      text-decoration: underline;
      color: $primary-color;
    }
    .submit {
      display: inline-block;
      background-color: white;
      color: $primary-color;
      padding: 5px 15px;
      font-size: 0.8em;
      border-radius: 12px;
      font-weight: bold;
    }
  }
}

#header {
  color: black;
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
  .container {
    background-color: white;
    margin: 0 auto;
    // max-width: 100%;
    // width: 100%;

    padding: $general-gutter;
    display: flex;
    // flex-direction: column;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: 700px) {
      padding: 10px;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    padding: 0;
    .logo-anchor {
      display: block;
      font-size: 0;
      picture,
      img {
        max-width: 60px;
        padding: 0;
        margin: 0;
        @media (min-width: 500px) {
          max-width: 180px;
        }
      }
    }
  }
  .search {
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    padding: 10px;
    .search-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      position: relative;
      input,
      button {
        height: 35px;
        padding: 0;
        margin: 0;
        display: inline-block;
        border: none;
        box-shadow: none;
        background-color: rgb(242, 242, 242);
      }
      input {
        width: 100%;
        padding: 0 calc(#{$search-input-button} + 20) 0 20px;
        font-size: 1em;
        -webkit-border-radius: 17px;
        -moz-border-radius: 17px;
        border-radius: 17px;
        -webkit-box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
        -moz-box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
        box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
      }
      button {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: $search-input-button;
        padding: 5px 15px 0;
        text-align: center;
        -webkit-border-top-right-radius: 17px;
        -webkit-border-bottom-right-radius: 17px;
        -moz-border-radius-topright: 17px;
        -moz-border-radius-bottomright: 17px;
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
        transition: all 0.2s;
        cursor: pointer;
        &:hover {
          background-color: #e6e6e6;
        }
        svg {
          fill: $primary-color;
        }
      }
    }
    .info {
      display: none;
    }
  }
  .menuItem {
    height: 50px;
    padding: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgb(250, 250, 250);
    }
    a {
      color: #111111;
    }
    .icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    @media (min-width: 400px) {
      padding: 0 15px;
    }
    @media (min-width: 700px) {
      .icon {
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    &.menuToggle {
      display: flex;
      @media (min-width: 900px) {
        display: none;
      }
    }
  }
  .menu {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    width: 100%;
    z-index: 2;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        a {
          display: block;
          color: white;
          padding: 20px 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          svg {
            fill: $primary-color;
          }
          &:hover {
            background-color: white;
            color: $secondary-color;
            svg {
              fill: $secondary-color;
            }
          }
          span.text {
            display: none;
          }
          .icon {
            position: relative;
            display: inline-block;
            .badge {
              position: absolute;
              bottom: 0;
              left: 0;
              margin-bottom: -11px;
              margin-left: -11px;
              font-size: 0.7em;
              font-weight: bold;
              background-color: rgb(175, 202, 5);
              color: rgb(34, 34, 34);
              width: 22px;
              height: 22px;
              display: flex;
              align-items: center;
              justify-content: center;
              -webkit-border-radius: 17px;
              -moz-border-radius: 17px;
              border-radius: 17px;
            }
          }
        }
      }
    }
  }

  @media (min-width: $md) {
    .container {
      flex-direction: row;
    }
    .logo {
      padding: 0;
      // order: 1;
    }
    .search {
      // order: 2;
      background-color: transparent;
      width: auto;
      padding: 0;
      margin: 0 10px;
      .search-wrapper {
        width: 400px;
        input,
        button {
          background-color: white;
        }
        input {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }
    .menu {
      // order: 3;
      background-color: transparent;
      box-shadow: none;
      width: auto;
      .show-search {
        display: none;
      }
      ul {
        li {
          a {
            color: white;
            svg {
              fill: white;
            }
            &:hover {
              color: $primary-color;
              svg {
                fill: $primary-color;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $lg) {
    // .container{
    // 	padding: 0 50px;
    // }

    .search {
      .info {
        display: block;
        margin-left: 15px;
        font-size: 0.8em;
        line-height: 1.5em;
        a {
          color: white;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .menu {
      ul {
        li {
          a {
            span.text {
              display: block;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
