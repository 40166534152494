@import "styles/variables";

.megamenu {
  display: flex;
  .mobile {
    display: none !important; //due to override in subclases
  }

  .closeButton {
    background: none;
    border: none;
    display: none;
    transform: rotate(45deg);
  }

  .separator {
    display: none;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $grey-light-color;
  }
  a {
    color: $text-color;
    text-decoration: none;
    font-size: 14px;
  }
  ul {
    list-style: none;
  }

  nav {
    z-index: 999;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 0;

    ul.main {
      display: flex;
      justify-content: space-around;
      //LEVEL 0
      > li {
        > a {
          display: flex;
          align-items: center;
          padding: 0 $general-gutter;
          color: $text-color;
          height: 50px;
          &:hover {
            color: $primary-color;
            background: rgb(250, 250, 250);
          }
        }
      }
    }
  }
}

@media (max-width: 899px) {
  .megamenu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    display: none;
    z-index: 20;

    &.open {
      display: block;
    }

    .closeButton {
      position: absolute;
      top: 5px;
      right: 15px;
      color: #ffffff;
      font-size: 30px;
      display: block;
      z-index: 20;
      padding: 0;
    }

    .mobile,
    .mobile a {
      display: flex !important; //due to override in subclases
      justify-content: space-between;
      align-items: center;
    }
    .separator {
      display: block;
      margin: 10px 0;
    }
    .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: $general-gutter;
      img {
        max-height: 40px;
      }
    }

    nav {
      background: white;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      margin: 0;
      overflow: auto;
      width: 85%;
      z-index: 4;
      box-shadow: $general-shadow;

      ul.main {
        padding: 0;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;

        //level 0
        > li {
          > a {
            display: none;
            font-size: 13px;
            font-weight: 400;
            height: auto;
            padding: 10px $general-gutter;

            .icon {
              font-size: 16px;
              margin-right: $general-gutter;
            }

            &.hasSubmenu {
              font-size: 17px;
              font-weight: bold;

              .icon {
                font-size: 16px;
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .submenu {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      flex-direction: column;
      height: 100%;
      width: 85%;
      background: #ffffff;
      padding: 0;
      list-style: none;
      overflow: auto;

      .icon {
        position: absolute;
        right: $general-gutter;
        top: 50%;
        transform: translateY(-50%);
        color: #000000;
        z-index: 10;
      }

      .header {
        font-weight: 600;
        border-bottom: 1px solid rgba($grey-color, 0.5);
        padding: $general-gutter/2 $general-gutter;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 5px;
        position: sticky;
        top: 0;
        background: #ffffff;
        z-index: 20;

        a {
          font-size: 20px;
        }

        .icon {
          right: auto;
          left: $general-gutter;
        }
      }

      > li {
        // border-bottom: 1px solid #d8d8d8;
        position: relative;

        > a {
          padding: $general-gutter/2 $general-gutter;

          &.hasSubmenu {
            // font-weight: 600;
            // font-size: 17px;
            // border-bottom: 1px solid rgba(216, 216, 216, 0.34);
          }

          .icon {
            font-size: 18px;
          }
        }
      }

      .special {
        background: #f3bc15;
        .icon,
        a {
          color: #ffffff;
        }
      }

      img {
        position: relative;
      }
    }
  }
}
