@import "styles/variables";

.itemsList {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;

    &.totalWithoutVAT {
      font-size: 13px;
    }

    &.totalWithVAT {
      margin-top: 15px;
      font-size: 1.6em;
      font-weight: bolder;
    }
  }
}

.actions {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  align-content: center;
  min-height: 90px;
  @media (min-width: $md) {
    height: auto;
    min-height: auto;
    flex-direction: row;
  }
}

.table {
  margin-bottom: 15px;
  table tbody tr td {
    div {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.alert {
  p:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    color: $primary-color;
  }
}
