@import "styles/variables";

.product {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  margin-bottom: 10px;

  img {
    max-width: 70px;
    margin-right: 10px;
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 100%;

    .name {
      font-size: 13px;
      color: $primary-color;
      font-weight: bold;
      display: block;
      text-align: left;
    }

    .addToCart {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;
      .price {
        font-size: 14px;
        font-weight: bold;
        color: $secondary-color;
        margin-right: 15px;
      }

      :global(.ant-input-number) {
        margin-right: 5px;
        width: 70px;
      }

      button {
        margin-left: 15px;
      }
    }
  }
}
