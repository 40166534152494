.newsletter {
    :global(.ant-row) {
        margin-bottom: 0px;
    }
  .agreementCheckboxWrapper {
    label {
      color: #ffffff;
    }
  }
}
