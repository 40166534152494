@import "styles/variables";

#product-detail {
  font-family: Merriweather;
  font-size: 14px;
  line-height: 24px;
  h3 {
    color: #111111;
    font-family: "Nunito Sans";
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    margin: $general-gutter;
    text-align: center;
  }
  .container {
    max-width: 1220px;
    margin: 0 auto;
    overflow: visible;
  }
  .row {
    position: relative;
    clear: both;
    width: 100%;
    overflow: visible;
    &:after {
      content: "";
      clear: both;
      display: table;
    }
    .col {
      padding: 0 15px;
      box-sizing: border-box;
      overflow: visible;
    }
  }
  .profile-photo {
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .gallery {
    position: relative;
    margin-bottom: 40px;
    .gallery-items {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow-x: scroll;
      .gallery-item {
        display: block;
        position: relative;
        box-sizing: border-box;
        margin-right: 10px;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 3px solid transparent;
          box-sizing: border-box;
          transition: all 0.3s;
        }
        &:hover {
          &:after {
            border-color: #56ab2f;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .gallery-item {
      img {
        display: block;
        max-width: 125px;
        box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
        margin: 0;
      }
    }
    .controls {
      margin-right: 20px;
      display: block;
      position: absolute;
      bottom: 100%;
      right: 0;
      a {
        display: inline-block;
        background-color: #56ab2f;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        color: white;
        text-decoration: none;
        margin-bottom: 30px;
        text-align: center;
        padding-top: 12px;
        box-sizing: border-box;
        margin-left: 10px;
        transition: all 0.3s;
        &:hover {
          background-color: #48701e;
        }
      }
    }
  }
  h1 {
    color: #111111;
    font-family: "Nunito Sans";
    font-size: 32px;
    font-weight: 800;
    line-height: 43px;
    margin-bottom: 0;
  }
  .subtitle {
    opacity: 0.5;
    color: #111111;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
  }
  .prices {
    .selling-price {
      color: #be100f;
      font-family: "Nunito Sans";
      font-size: 2em;
      font-weight: bold;
      display: inline-block;
      margin-right: 30px;
    }
    .origin-price {
      color: #111111;
      font-family: "Nunito Sans";
      font-size: 1.5em;
      display: inline-block;
      text-decoration: line-through;
    }
    @media (max-width: $md) {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: baseline !important;
      .selling-price {
        font-size: 1.8em;
        margin: 0;
      }
      .origin-price {
        margin-left: 10px;
        font-size: 1em;
      }
    }
  }
  .lowestPrice {
    font-size: 12px;
    position: absolute;
    top: calc(100% + 5px);
    strong {
      white-space: nowrap;
      padding-left:2px
    }
  }
  .add-to-cart {
    box-sizing: border-box;
    background-color: white;
    padding: $general-gutter * 2;
    margin: $general-gutter 0;
    box-shadow: $general-shadow;
    .cart-button-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .alertText {
        color: $alert-text-color;
      }
      & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        & > span {
          margin-right: 20px;
          & > div {
            margin-right: $general-gutter;
          }
        }
        button {
          height: 40px;
        }
        input {
          height: 40px;
        }
      }
      @media (max-width: $md) {
        display: flex;
        align-items: center;
        & > div {
          display: flex;
          // flex-direction: column;
          justify-content: space-between;
          text-align: center;
          & > span {
            display: block;
            & > div {
              // width: 55px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    @media (max-width: $md) {
      padding: $general-gutter * 2 $general-gutter;
    }

    .buttons {
      display: inline-flex;
      align-items: center;
    }
  }
  .tabs-wrapper {
    margin-top: 40px;
    .tabs {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      a {
        display: inline-block;
        color: #56ab2f;
        font-family: Merriweather;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        padding: 13px 35px;
        text-decoration: none;
      }
      a.active {
        background-color: white;
      }
    }
    .tab-content {
      background: white;
      padding: 20px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
      font-family: Merriweather;
      ul {
        padding-left: 30px;
        li {
          margin-bottom: 30px;
          list-style-type: none;
          position: relative;
          &:before {
            display: block;
            content: "";
            width: 10px;
            height: 10px;
            border: 1px solid #a6c080;
            position: absolute;
            left: -30px;
            top: 6px;
            border-radius: 2px;
          }
        }
      }
    }
  }
  .ingredients-section {
    margin: $general-gutter 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    ul {
      padding-left: 40px;
      li {
        margin-bottom: $general-gutter;
        list-style-type: none;
        position: relative;
        margin-bottom: 0;
        line-height: 28px;
        &:before {
          display: block;
          content: "";
          width: 10px;
          height: 10px;
          border: 1px solid #a6c080;
          position: absolute;
          left: -30px;
          top: 6px;
          border-radius: 2px;
        }
      }
    }
    h2 {
      color: #111111;
      font-family: "Nunito Sans";
      font-size: 32px;
      font-weight: 800;
      line-height: 43px;
      margin-top: 0;
    }
    img {
      max-width: 100%;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
    }
  }
  .top-parameters {
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    margin: 50px 0;
    .parameter {
      width: 137px;
      height: 137px;
      border: 2px solid #56ab2f;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      justify-content: space-around;
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
      img {
        margin-top: 15px;
      }
      .parameter-value {
        color: #56ab2f;
        margin-bottom: 15px;
      }
    }
  }
  .crosssale {
    background: white;
    padding: 50px 0 100px;
    .text-right {
      text-align: right;
    }
    h2 {
      color: #111111;
      font-family: "Nunito Sans";
      font-size: 32px;
      font-weight: 800;
      line-height: 43px;
      margin-bottom: 60px;
      margin-top: 0;
      display: none;
    }
    .button-holder {
      text-align: center;
      display: block;
      width: 100%;
      overflow: auto;
      margin-bottom: 30px;
      .button-group {
        display: inline-flex;
        .button {
          color: #56ab2f;
          text-decoration: none;
          background: white;
          padding: 13px 50px;
          font-family: Merriweather;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          border: 1px solid #56ab2f;
          border-right-width: 0;
          box-sizing: border-box;
          transition: all 0.3s;
        }
      }
    }
    .button-group {
      .button {
        &:last-child {
          border-right-width: 1px;
        }
        &:hover {
          background-color: #56ab2f;
          color: white;
        }
      }
      .button.active {
        background-color: #56ab2f;
        color: white;
      }
    }
    .crosssale-carousel {
      .no-padding {
        padding: 0;
      }
      .crosssale-carousel-item {
        h3 {
          color: #111111;
          font-family: "Nunito Sans";
          font-size: 24px;
          font-weight: 800;
          line-height: 32px;
          margin: 0;
        }
        .brand {
          color: #56ab2f;
          font-family: "Nunito Sans";
          font-size: 16px;
          font-weight: 800;
          line-height: 22px;
          margin-bottom: 30px;
        }
        .short-description {
          color: #111111;
          font-family: Merriweather;
          font-size: 14px;
          line-height: 24px;
        }
        .prices {
          .selling-price {
            color: #111111;
            font-family: "Nunito Sans";
            font-size: 18px;
            font-weight: 800;
            line-height: 24px;
          }
        }
        img {
          max-width: 100%;
          box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
        }
        .controls {
          display: none;
          a {
            display: inline-block;
            background-color: #56ab2f;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            color: white;
            text-decoration: none;
            margin-bottom: 30px;
            text-align: center;
            padding-top: 12px;
            box-sizing: border-box;
            margin-left: 10px;
            transition: all 0.3s;
            &:hover {
              background-color: #48701e;
            }
          }
        }
      }
    }
  }
  .recipe-process {
    padding: $general-gutter * 2 0;
    h2 {
      color: #111111;
      font-family: "Nunito Sans";
      font-size: 32px;
      font-weight: 800;
      line-height: 43px;
      text-align: center;
      margin-bottom: $general-gutter * 2;
    }
    .recipe-video {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: $general-gutter * 3 auto;
      div {
        display: inline-block;
      }
    }
    .recipe-step {
      background: white;
      box-shadow: $general-shadow;
      margin-bottom: $general-gutter;
      img {
        max-width: 100%;
      }
      .recipe-step-content {
        padding: $general-gutter $general-gutter * 2;
        h3 {
          color: #111111;
          font-family: "Nunito Sans";
          font-size: 24px;
          font-weight: 800;
          line-height: 32px;
          margin: 0 0 $general-gutter;
          &::first-letter {
            color: #56ab2f;
            font-family: "Nunito Sans";
            font-size: 32px;
            font-weight: 800;
            line-height: 43px;
          }
        }
        .text {
          display: flex;
          align-items: center;
          min-height: 150px;
        }
      }
      &.transparent {
        background: transparent;
        box-shadow: none;
        .recipe-step-content {
          h3 {
            text-align: left;
          }
          .text {
            text-align: left;
          }
        }
      }
    }
  }
}
.breadcrumbs {
  margin: 20px 0;
  font-family: "Nunito Sans";
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  ul {
    padding: 0;
    li {
      display: inline-block;
      padding-right: 3px;
      &:after {
        content: ">";
        color: #56ab2f;
        margin-left: 3px;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      a {
        color: #56ab2f;
        display: inline-block;
        text-decoration: none;
      }
    }
  }
}
@media (min-width: 776px) {
  #product-detail {
    .row {
      .col {
        float: left;
        width: 50%;
      }
      .col.col-12 {
        width: 100%;
      }
      .col.col-5 {
        width: 41.66%;
      }
      .col.col-6 {
        width: 50%;
      }
      .col.col-8 {
        width: 66.66%;
      }
      .col.col-4 {
        width: 33.33%;
      }
      .col.col-1 {
        width: 8.33%;
      }
    }
    .crosssale {
      h2 {
        display: block;
      }
      .button-holder {
        text-align: right;
        margin-bottom: 0;
      }
    }
  }
}
@media (min-width: 991px) {
  #product-detail {
    .crosssale {
      .crosssale-carousel {
        .crosssale-carousel-item {
          .controls {
            display: block;
          }
        }
      }
    }
  }
}
