@import "styles/variables";

$search-input-button: 60px;

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.search-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  .formWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    form {
      width: 100%;
      position: relative;

      // @media (min-width: $sm) {
      //   width: 70%;
      // }

      // @media (min-width: $md) {
      //   width: 50%;
      // }
    }
    input,
    button {
      height: 35px;
      padding: 0;
      margin: 0;
      display: inline-block;
      border: none;
      box-shadow: none;
      background-color: rgb(242, 242, 242);
    }
    input {
      border: 1px solid $primary-color;
      width: 100%;
      padding: 0 95px 0 10px !important;
      font-size: 1em;
      -webkit-box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
      -moz-box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
      box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
    }
    .clearInput {
      position: absolute;
      right: 70px;
      display: flex;
      top: 0;
      align-items: center;
      cursor: pointer;
      color: $grey-color;
      height: 100%;
    }
    button.submit {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      background-color: $primary-color;
      width: $search-input-button;
      padding: 5px 15px 0;
      text-align: center;
      transition: all 0.2s;
      cursor: pointer;
      &:hover {
        background-color: #e6e6e6;
      }
      svg {
        fill: white;
      }
    }

    .closeButton {
      position: absolute;
      top: calc(100% + 20px);
      z-index: 4;
      right: 5px;

      @media (min-width: $sm) {
        position: relative;
        top: 0;
      }
    }
  }
  @media (min-width: $md) {
    input {
      background-color: white;
    }
    button {
      color: white;
      fill: white;
      background-color: $primary-color;
    }
    input {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .results {
    position: absolute;
    left: -20px;
    right: -20px;
    top: calc(100% + 10px);
    z-index: 3;
    height: 100%;

    @media (min-width: $md) {
      left: -10px;
      right: -10px;
    }

    .resultsWrapper {
      position: relative;
      display: flex;
      width: 100%;
      background: white;
      flex-direction: column;
      max-height: 600px;
      overflow-y: scroll;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 25px;
      padding-bottom: 10px;

      @media (min-width: $md) {
        width: 100%;
        max-height: none;
        flex-direction: row;
        overflow-y: visible;
      }

      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        color: $grey-color;
        display: block;
        font-size: 20px;
        cursor: pointer;
      }

      h2 {
        font-size: 1.3em;
        height: 30px;
        line-height: 30px;
        font-weight: bolder;
        margin-bottom: 15px;
        color: $primary-color;
      }

      .topProducts {
        width: 100%;
        background: white;
        border-bottom: 1px solid rgba($grey-color, 0.3);
        padding: 10px 20px;
        margin-bottom: 15px;

        @media (min-width: $md) {
          width: 25%;
          border-right: 1px solid rgba($grey-color, 0.3);
          border-bottom: none;
          margin-bottom: 0;
        }

        .topProductsWrapper {
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;
          @media (min-width: $md) {
            flex-direction: column;
            margin-bottom: 0;
          }

          button {
            color: white;
            fill: white;
            background-color: $primary-color;
          }
          input {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
          }
        }
      }

      .resultsLeft {
        width: 100%;
        background: white;
        padding: 10px 20px 30px 20px;
        border-bottom: 1px solid rgba($grey-color, 0.3);
        margin-bottom: 10px;

        @media (min-width: $md) {
          margin-bottom: 0px;
          border-right: 1px solid rgba($grey-color, 0.3);
          border-bottom: none;
          width: 45%;
          overflow-y: scroll;
          padding: 10px 30px;
        }
      }

      .resultsRight {
        width: 100%;
        padding: 10px 20px;

        @media (min-width: $md) {
          width: 30%;
          padding: 10px 30px;
        }

        .categoriesWrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media (min-width: $sm) {
            flex-direction: row;
          }

          @media (min-width: $md) {
            flex-direction: column;
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            &:last-child {
              margin-bottom: 20px;
            }

            &.inline {
              width: 100%;

              li {
                display: inline-flex;
                margin: 5px 5px 0 0;
              }
            }

            li {
              list-style: none;
              margin-bottom: 5px;

              a {
                padding: 10px 0;
                color: $text-color;

                &:hover {
                  text-decoration: underline;
                }

                &.tag {
                  border-radius: 15px;
                  background: white;
                  padding: 5px 10px;
                  font-size: 13px;

                  &:hover {
                    background: $primary-color;
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
