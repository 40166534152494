@import "styles/variables";

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;

    @media (min-width: $md) {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      width: 100%
    }

  a {
    text-align: center;
  }

  img {
    max-width: 100%;
  }

  .name {
    font-size: 14px;
    color: $primary-color;
    font-weight: bold;
    display: block;
    margin: 10px 0 15px 0;
    text-align: center;

    @media (min-width: $md) {
      font-size: 13px;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media (min-width: $md) {
      flex-direction: row;
      justify-content: space-evenly;
    }

    .price {
      font-size: 15px;
      font-weight: bold;
      color: $secondary-color;
      margin-bottom: 10px;

      @media (min-width: $md) {
        margin-bottom: 0;
      }
    }
  }
}
